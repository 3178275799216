import axios from 'axios';
import { selectorFamily, atom } from 'recoil';
import { buildQueryUrl } from '../utils/common';
import {
  CLIENT_DETAILS_QUERY,
  PAGE_DIRECTORY_QUERY,
} from '../sqlQueries';

export const currentUserNameQuery = selectorFamily({
  key: 'CurrentUserName',
  get: (client_name) => async () => {
    const query = buildQueryUrl(CLIENT_DETAILS_QUERY, {
      client_name,
    });
    //console.log('query', query);
    const response = await axios.get(query);
    //console.log('response', response);
    return response.data.rows[0];
  },
});
export const currentPageDirectoryQuery = selectorFamily({
  key: 'CurrentUserPageDirectory',
  get: (client_id) => async () => {
    const query = buildQueryUrl(PAGE_DIRECTORY_QUERY, {
      client_id,
    });
    //console.log('query', query);
    const response = await axios.get(query);
    //console.log('response', response);
    return response.data.rows;
  },
});

export const themeState = atom({
  key: 'isLightTheme',
  default: true,
});

// Maintain consistent login, get the current user in local storage
// and set to initial state of atom currentUser
export const noUser = {
  login: false,
  username: null,
  userId: null,
  token: null,
  responseCode: 0,
};

const existingUser = JSON.parse(localStorage.getItem('user'));
export const loginState = atom({
  key: 'currentUser',
  default: {
    ...noUser,
    ...existingUser,
  },
});
