import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { loginState } from '../recoil/state';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const user = useRecoilValue(loginState);
  return (
    <Route
      {...rest}
      component={(props) =>
        user.login ? 
          <Component {...props} /> : 
          <Redirect
            to={{ pathname: "/", state: { referer: props.location } }}
          />
      }
    />
  );
};

export default PrivateRoute;
