import React, { Suspense } from 'react';

import AppRouter from './routes/AppRouter';
import { Provider } from 'react-redux';
import store from './store';
import 'antd/dist/antd.css';
import { RecoilRoot } from 'recoil';
import Loading from './components/elements/Loading';

const App = () => {
  return (
    <Provider store={store}>
      <RecoilRoot>
        <Suspense fallback={<Loading />}>
          <AppRouter />
        </Suspense>
      </RecoilRoot>
    </Provider>
  );
};
export default App;
