import numeral from 'numeral';

const chartColors = [
  'rgba(122, 81, 138, 1)',
  'rgba(127, 171, 206, 1)',
  'rgba(233, 130, 68, 1)',
  'rgba(78, 115, 190, 1)',
  'rgba(89, 195, 100, 1)',
  'rgba(237, 28, 36, 1)',
  'rgba(112, 18, 56, 1)',
  'rgba(255, 255, 255, 1)',
  'rgba(255, 99, 132, 1)',
  'rgba(215, 44, 30, 1)',
];

export const barChartItemStyles = chartColors.map((color) => {
  return {
    backgroundColor: color,
    borderColor: color,
    borderWidth: 1,
    hoverBackgroundColor: color,
    hoverBorderColor: color,
  };
});

export const barHorizontalChartOptions = (
  xAxes,
  yAxes,
  chartHeader,
) => {
  return {
    maintainAspectRatio: false,

    legend: {
      display: true,
      labels: {
        //fontColor: '#fff'
      },
    },
    responsive: true,
    intersect: true,
    chartArea: {
      backgroundColor: 'rgb(33, 42, 52)',
      bottom: 950,
    },
    title: {
      display: chartHeader === '' ? false : true,
      text: chartHeader,
      fontSize: 22,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            callback: (value) => {
              return numeral(value).format('0,0');
            },
          },
          scaleLabel: {
            display: true,
            labelString: xAxes,
            fontStyle: 'bold',
            fontSize: 16,
          },
          gridLines: {
            color: 'rgb(62, 95, 119)',
            borderDash: [1, 5],
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: yAxes,
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            beginAtZero: true,
            fontColor: 'rgb(98, 112, 123)',
          },
          gridLines: {
            color: 'rgb(33, 42, 52)',
            borderDash: [1, 1000],
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      mode: 'label',
      intersect: false,
      displayColors: false,
      callbacks: {
        label: (tooltipItem, data) => {
          let item =
            data.datasets[tooltipItem.datasetIndex].data[
              tooltipItem.index
            ];
          return `${numeral(item).format('0,0')}`;
        },
      },
    },
  };
};

export const barVerticalChartOptions = (
  xAxes,
  yAxes,
  chartHeader = '',
) => {
  return {
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {},
    },
    responsive: true,
    intersect: true,
    chartArea: {
      backgroundColor: 'rgb(33, 42, 52)',
      bottom: 100,
    },
    title: {
      display: chartHeader === '' ? false : true,
      text: chartHeader,
      fontSize: 22,
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: xAxes,
            fontStyle: 'bold',
            fontSize: 16,
          },
          gridLines: {
            color: 'rgb(33, 42, 52)',
            borderDash: [1, 1000],
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            callback: (value) => {
              return numeral(value).format('0,0');
            },
            beginAtZero: true,
            fontColor: 'rgb(98, 112, 123)',
          },
          scaleLabel: {
            display: true,
            labelString: yAxes,
            fontStyle: 'bold',
            fontSize: 16,
          },
          gridLines: {
            color: 'rgb(62, 95, 119)',
            borderDash: [1, 5],
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      mode: 'label',
      intersect: false,
      displayColors: false,
      callbacks: {
        label: (tooltipItem, data) => {
          let item =
            data.datasets[tooltipItem.datasetIndex].data[
              tooltipItem.index
            ];
          return `${numeral(item).format('0,0')}`;
        },
      },
    },
  };
};
export const pyramidChartOptions = (chartHeader = '') => {
  return {
    responsive: true,
    chartArea: {
      backgroundColor: 'rgb(33, 42, 52)',
      bottom: 100,
    },
    title: {
      display: chartHeader === '' ? false : true,
      text: chartHeader,
      fontSize: 22,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            callback: (value) => {
              if (value >= 0) {
                return numeral(value).format('0,0');
              } else {
                return numeral(value * -1).format('0,0');
              }
              //return numeral(value).format('0,0');
            },
          },
          gridLines: {
            color: 'rgb(62, 95, 119)',
            borderDash: [1, 5],
          },
          scaleLabel: {
            display: true,
            fontStyle: 'bold',
            fontSize: 16,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            fontColor: 'rgb(98, 112, 123)',
            callback: (value) => {
              return value;
              //return numeral(value).format('0,0');
            },
          },
          gridLines: {
            color: 'rgb(62, 95, 119)',
            borderDash: [1, 1000],
          },
          scaleLabel: {
            display: true,
            fontStyle: 'bold',
            fontSize: 16,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          let item =
            data.datasets[tooltipItem.datasetIndex].data[
              tooltipItem.index
            ];
          if (item < 0) {
            item = item * -1;
          }
          const label = data.datasets[tooltipItem.datasetIndex].label;
          return `${label}: ${numeral(item).format('0,0')}`;
        },
      },
    },
  };
};

export const lineChartOptionsWestPac = (
  xAxes,
  yAxes,
  chartHeader = '',
) => {
  return {
    responsive: true,
    chartArea: {
      backgroundColor: '#eaeaea',
      bottom: 100,
    },
    title: {
      display: chartHeader === '' ? false : true,
      text: chartHeader,
      fontSize: 22,
    },
    borderColor: 'red',
    hover: {
      mode: 'dataset',
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: xAxes,
            fontStyle: 'bold',
            fontSize: 16,
          },
          gridLines: {
            color: 'rgb(33, 42, 52)',
            borderDash: [1, 1000],
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            callback: (value) => {
              return numeral(value).format('0,0');
            },
          },
          scaleLabel: {
            display: true,
            labelString: yAxes,
            fontStyle: 'bold',
            fontSize: 16,
          },
          gridLines: {
            color: 'rgba(0, 0, 0, 0.1)',
            //borderDash: [1, 5],
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      mode: 'label',
      intersect: false,
      displayColors: false,
      callbacks: {
        label: (tooltipItem, data) => {
          //console.log('data', data)
          let item =
            data.datasets[tooltipItem.datasetIndex].data[
              tooltipItem.index
            ];
          const label = data.datasets[tooltipItem.datasetIndex].label;
          const formattedNumber =
            item % 1 !== 0 ? item : numeral(item).format('0,0');
          return `${label}: ${formattedNumber}`;
        },
      },
    },
  };
};

export const lineChartOptionsCityCompass = (
  xAxes,
  yAxes,
  chartHeader = '',
) => {
  return {
    responsive: true,
    chartArea: {
      backgroundColor: 'rgb(33, 42, 52)',
      bottom: 100,
    },
    title: {
      display: chartHeader === '' ? false : true,
      text: chartHeader,
      fontSize: 22,
    },
    borderColor: 'red',
    hover: {
      mode: 'dataset',
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: xAxes,
            fontStyle: 'bold',
            fontSize: 16,
          },
          gridLines: {
            color: 'rgb(33, 42, 52)',
            borderDash: [1, 1000],
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            callback: (value) => {
              return numeral(value).format('0,0');
            },
          },
          scaleLabel: {
            display: true,
            labelString: yAxes,
            fontStyle: 'bold',
            fontSize: 16,
          },
          gridLines: {
            color: 'rgb(62, 95, 119)',
            borderDash: [1, 5],
          },
        },
      ],
    },
    tooltips: {
      enabled: true,
      mode: 'label',
      intersect: false,
      displayColors: false,
      callbacks: {
        label: (tooltipItem, data) => {
          //console.log('data', data)
          let item =
            data.datasets[tooltipItem.datasetIndex].data[
              tooltipItem.index
            ];
          const label = data.datasets[tooltipItem.datasetIndex].label;
          const formattedNumber =
            item % 1 !== 0 ? item : numeral(item).format('0,0');
          return `${label}: ${formattedNumber}`;
        },
      },
    },
  };
};
