import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { loginState } from '../recoil/state';
import { useRecoilValue } from 'recoil';
import { default_pagecode } from './AppRouter';

const PublicRoute = ({ component: Component, ...rest }) => {
  const user = useRecoilValue(loginState);
  return (
    <Route
      {...rest}
      component={(props) =>
        user.login ? (
          <Redirect
            to={{
              pathname: `/${default_pagecode}`,
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
