import axios from 'axios';
import { barChartItemStyles } from './chart';
import _ from 'lodash';

export const gridColumns = 6;

export const buildQueryUrl = (
  query,
  params = {},
  format = 'json',
) => {
  //const baseURL = `https://maps.geografia.com.au`;
  const baseURL = `https://louse.geodb.host/${
    process.env.ENABLE_CACHED_API ? 'cached' : ''
  }`;
  const sqlApi = `user/westpacfederal/api/v2/sql`;

  let arr = [];
  for (const [key, value] of Object.entries(params)) {
    let obj = {};
    obj[key] = value;
    arr.push(obj);
  }
  const q = replaceSqlContent(arr, query);
  let output = `${baseURL}/${sqlApi}?q=${q}`;
  if ('format' in params) {
    output += `&format=${params.format}`;
  } else {
    output += `&format=${format}`;
  }
  //console.log('params 1: ',params,'output', output)
  return output;
};

export const getData = async (queryName, params) => {
  const response = await axios.get(buildQueryUrl(queryName, params));
  //TODO: catch error
  return response;
};

export const authenticate = async ({ username, password }) => {
  const baseURL =
    'https://geografia-user-admin.herokuapp.com/api/westpac_federal_user.json';
  axios.interceptors.response.use(
    (response) => {
      //console.log('interceptor', response);
      return response;
    },
    (error) => {
      //console.log('error', error);
      return {
        login: false,
        username: null,
        userId: null,
        token: null,
        responseCode: error.response.status,
      };
    },
  );

  const response = await axios.get(baseURL, {
    auth: {
      username: username,
      password: password,
    },
  });

  //console.log('response', response);
  if (response.status === 200) {
    const user = {
      login: true,
      username,
      userId: response.data.id,
      token: response.data.zendesk_sso_token,
      responseCode: response.status,
    };
    localStorage.setItem('user', JSON.stringify(user));
    return user;
  } else {
    //this response is the interceptor returned value from above interceptor method above
    return response;
  }
};

export const getAllData = async (queries, params) => {
  const queryUrls = queries.map((query) =>
    axios.get(buildQueryUrl(query, params)),
  );
  const response = await axios
    .all(queryUrls)
    .then(
      axios.spread((...responses) => {
        return responses.map((response) => {
          return response.data.rows;
        });
      }),
    )
    .catch(() => {
      // TODO: need to catch error here
    });
  return response;
};

export const getGeoJSONData = (queryName) => {
  return buildQueryUrl(queryName, {}, 'geojson');
};

export const getGeoJSONUrl = (query, params) =>
  buildQueryUrl(query, params, 'geojson');

export const makeInputData = (inputArray, inputType = '') => {
  let result = [];
  inputArray.forEach((item, index) => {
    for (const [key, value] of Object.entries(item)) {
      if (value !== null) {
        const newKey =
          inputType === 'content'
            ? `{{data.${index}.${key}}}`
            : `{{${key}}}`;
        const outputObj = {};
        outputObj[newKey] = value;
        result.push(outputObj);
      }
    }
  });
  return result;
};

export const replaceContent = (inputArray, inputString) => {
  let newString = inputString;
  for (let item = 0; item <= inputArray.length - 1; item++) {
    const regexp = new RegExp(Object.keys(inputArray[item])[0], 'g');
    newString = newString.replace(
      regexp,
      Object.values(inputArray[item])[0],
    );
    //console.log('newString', newString)
  }
  return newString;
};
export const replaceSqlContent = (inputArray, inputSql) => {
  let newSql = inputSql;
  for (let item = 0; item <= inputArray.length - 1; item++) {
    const key = `{{${Object.keys(inputArray[item])[0]}}}`;
    const regexp = new RegExp(key, 'g');
    newSql = newSql.replace(
      regexp,
      `${Object.values(inputArray[item])[0]}`,
    );
    //console.log('newSql', newSql)
  }
  return newSql;
};

export const replaceSeries = (inputFilters, inputSeries) => {
  const output = inputSeries.map((series) => {
    return replaceSqlContent(inputFilters, series);
  });
  //console.log('output', output)
  return output;
};

export const makeHeading = (input) => {
  const output = input
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return output;
};
export const updateFiltersFromDropdownEvent = (
  inputArray,
  { title, value },
) =>
  inputArray.map((item) => {
    //allows merging values by their keys. if the key have - and value have _ it assumes they are multiple keys and values.
    if (_.includes(value, '_') && _.includes(title, '-')) {
      const values = value.split('_');
      const titles = title.split('-');
      const newObj = titles.map((titleItem, titleIndex) => {
        return {
          [titleItem]: values[titleIndex],
        };
      });
      const newObjMerge = _.assign.apply(_, newObj);
      //console.log('newObj', newObj, 'newObjMerge',newObjMerge,'item', item, 'Object.keys(item)', Object.keys(item), 'Object.keys(newObj)', Object.keys(newObj[0]))
      if (_.isEqual(Object.keys(item), Object.keys(newObjMerge))) {
        return newObjMerge;
      } else {
        return item;
      }
    } else {
      return Object.keys(item).includes(title)
        ? {
            ...item,
            [title]: value,
          }
        : item;
    }
  });

export const makeUrlQueryString = (inputArray) => {
  const params = inputArray.reduce((memo, curr) => {
    Object.keys(curr).map((item, index) => {
      const value = encodeURIComponent(Object.values(curr)[index]);
      memo.push(`${item}=${value}`);
      return memo;
    });
    return memo;
  }, []);

  return '?' + params.join('&');
};

// removes the "{" and "}" surrounding the query coz SQL queries can't be stored in a database field without these
export const santaizeSql = (rawSql) => {
  const re = new RegExp(/^\{(.*)\}$/s);
  const match = re.exec(rawSql);
  return match ? match[1] : rawSql;
};

export const convertAjdPageToMetaData = ({
  page_code,
  page_filters,
  a_title,
  b_title,
  c_title,
  d_title,
  slug,
}) => {
  return {
    pageMetaData: {
      page_titles: {
        a_title,
        b_title,
        c_title,
        d_title,
      },
      page_filters,
    },
    page_code,
    slug,
  };
};

export const arrayToObject = (array) =>
  array.reduce((obj, item) => {
    obj = { ...obj, ...item };
    return obj;
  }, {});

export const getSelectedFilterValue = (
  filterItems,
  selectedFilters,
) => {
  const findObjectKey = Object.keys(filterItems[0])[0];
  const foundObject = selectedFilters.find((selectedFilter) => {
    return Object.keys(selectedFilter)[0] === findObjectKey;
  });
  const filterValue = foundObject[findObjectKey];
  return filterValue;
};

export const makeChartLabel = (inputArray, label) => {
  const labels = inputArray.map((item) => {
    return item[label];
  });
  return _.uniqBy(labels);
};

const negative = (n) => {
  return n * -1;
};

export const makeChartDataSets = (
  inputArray,
  datasets,
  valueInput,
  isLineChart = false,
) => {
  const groups = _.groupBy(inputArray, datasets);
  const dataOutput = Object.keys(groups).map((group, index) => {
    let data = _.map(groups[group], valueInput);
    const lineFill = isLineChart ? { fill: false } : {};
    //console.log('data', data, 'index', index, 'groups[group]', groups[group], 'valueInput', valueInput)
    return {
      label: group,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      data,
      ...barChartItemStyles[index],
      ...lineFill,
    };
  });
  //console.log('dataOutput', dataOutput)
  return dataOutput;
};

export const sorter = (name) => {
  return (a, b) => {
    return a[name] > b[name] ? 1 : -1;
  };
};

export const makePyramidDataSets = (
  inputArray,
  leftSeries,
  rightSeries,
) => {
  let leftGroup = leftSeries.map((series, index) => {
    let data = _.map(inputArray, series);
    data = _.map(data, negative);
    const styleIndex = index === 0 ? index : index + 1;
    return {
      label: series,
      data,
      ...barChartItemStyles[styleIndex],
    };
  });
  //console.log('leftGroup', leftGroup)
  let rightGroup = rightSeries.map((series, index) => {
    let data = _.map(inputArray, series);
    const styleIndex = index === 0 ? index + 1 : index + 2;
    return {
      label: series,
      data,
      ...barChartItemStyles[styleIndex],
    };
  });
  //console.log('rightGroup', rightGroup)
  const output = [...leftGroup, ...rightGroup];
  return output;
};

export const getFeatureType = (featureCollection, featureType) => {
  let result = {};
  result.type = featureCollection.type;
  result.features = _.filter(featureCollection.features, {
    geometry: { type: featureType },
  });
  return result;
};

export const pageCodeToObjectPath = (page_code = 'A1B5C1D4') => {
  const re = /([A-Z]\d+)|([A-Z])/g;
  let matches = page_code.match(re);
  if (!matches) return;

  return matches.reduce((path, current, idx) => {
    if (idx === 0) {
      // A Level path is different
      // "A1".match(/\d+/) => ["1", index: 1, input: "A1", groups: undefined]
      let aIndex = current.match(/\d+/);
      if (aIndex) {
        aIndex = parseInt(aIndex[0]) - 1;
        path = `${path}[${aIndex}]`;
      }
    } else {
      let levelLetter = current.match(/([A-Z])/)[0].toLowerCase();
      path = `${path}.${levelLetter}`;
      let levelIndex = current.match(/(\d+)/);
      if (levelIndex) {
        let _levelIndex = parseInt(levelIndex[0]) - 1;
        path = `${path}[${_levelIndex}]`;
      }
    }
    return path;
  }, '');
};

export const pageDepth = (page_code) => {
  const re = /([A-Z]\d+)|([A-Z])/g;
  let m = page_code.match(re);
  return m ? m.length : m;
};

// Object.byString(pageDirectory, pageCodeToObjectPath("A1B2"))
export const byString = function (o, s) {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  var a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const sqlQueryTransforms = (rows) => {
  let data = rows.reduce((memo, current) => {
    const found = memo.find(
      (obj) => obj['a_level'] === current['a_level'],
    );

    if (!found) {
      memo.push({
        a_level: current['a_level'],
        a_title: current['a_title'],
        page_filters: current.page_filters,
        options: current.options,
      });
    }

    return memo;
  }, []);

  // a_level: "A1"
  // a_title: ""
  // b_level: "B2"
  // b_title: "Headline Indicators"
  // c_level: ""
  // c_title: ""
  // d_level: ""
  // d_title: ""
  // page_code: "A1B2"
  // page_filters: "{"area": "SELECT distinct(ben
  // get Bs for A1 (later do in loop for each A*)

  // get Bs for A1 (later do in loop for each A*)
  data.map(
    ({ a_level }, index) =>
      (data[index]['b'] = _.uniqBy(
        rows
          .filter((r) => r['a_level'] === a_level)
          .filter(({ b_level }) => b_level)
          .map(
            ({ b_level, b_title, page_filters, options, slug }) => ({
              b_level,
              b_title,
              page_code: `${a_level}${b_level}`,
              page_filters: page_filters,
              slug: slug,
              options: options,
              // map over cs for this b
              c: _.uniqBy(
                rows
                  .filter(
                    (r) =>
                      r['a_level'] === a_level &&
                      r['b_level'] === b_level,
                  )
                  .filter(({ c_level }) => c_level)
                  .map(
                    ({
                      c_level,
                      c_title,
                      page_filters,
                      options,
                      slug,
                    }) => ({
                      c_level,
                      c_title,
                      page_code: `${a_level}${b_level}${c_level}`,
                      page_filters: page_filters,
                      slug: slug,
                      options: options,
                      d: _.uniqBy(
                        rows
                          .filter(
                            (r) =>
                              r['a_level'] === a_level &&
                              r['b_level'] === b_level &&
                              r['c_level'] === c_level,
                          )
                          .filter(({ d_level }) => d_level)
                          .map(
                            ({
                              d_level,
                              d_title,
                              page_filters,
                              options,
                              slug,
                            }) => ({
                              d_level,
                              d_title,
                              page_code: `${a_level}${b_level}${c_level}${d_level}`,
                              page_filters: page_filters,
                              slug: slug,
                              options: options,
                            }),
                          ),
                        'page_code',
                      ),
                    }),
                  ),
                'page_code',
              ),
            }),
          ),
        'page_code',
      )),
  );
  return data;
};

export const getAdjacentPageDirectory = (
  pageDirectory,
  route_page_code,
) => {
  const adjacentPageCode = route_page_code.substr(
    0,
    route_page_code.match(/(\d+$)/).index,
  );
  const result = byString(
    pageDirectory,
    pageCodeToObjectPath(adjacentPageCode),
  );
  return result;
};
