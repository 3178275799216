import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

const Wrapper = styled.div`
  text-align: center;
`;
const Loading = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <Wrapper>
      <Spin indicator={antIcon} />
    </Wrapper>
  );
};
export default Loading;
