import React, { lazy } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import ErrorPage from '../views/ErrorPage';
//Lazy and suspense loading components for code splitting
const Login = lazy(() => import('../components/elements/Login'));
const Root = lazy(() => import('../containers/Root'));

export const history = createBrowserHistory();
export const default_pagecode = 'A1B1-home';

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <PublicRoute path="/" component={Login} exact={true} />
      <PrivateRoute path="/:route_page_code_slug" component={Root} />
      <Route component={ErrorPage} />
    </Switch>
  </Router>
);

export default AppRouter;
